<!-- B2C 订单列表组件 -->
<template>
	<div v-loading.fullscreen="uploadloading" :lang="$i18n.locale" element-loading-text="$t('tipsInfo.uploading')"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" class="app-container">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<!-- 查询货架信息 -->
			<el-tab-pane :label="$t('chinaWhB2COrderPage.listTabName')" name="first">
				<div class="listHeader">
					<i></i><span>{{$t('chinaWhB2COrderPage.B2ClistTabName')}}</span>
				</div>
				<el-form class="search" ref="listForm" :inline="true">
					<el-form-item label>
						<!-- 航空提单号查询 -->
						<!-- @keyup.enter.native="getBox" -->
						<el-input type="textarea" v-model="boxNum" autosize
							:placeholder="$t('chinaWhB2COrderPage.listTabSearchItemAWB_ph')" style="z-index: 999">
						</el-input>
					</el-form-item>
					<el-form-item label>
						<el-input type="textarea" v-model="input" autosize
							:placeholder="$t('commonInfo.clientOrderNoColumn')" style="width: 250px; "></el-input>
					</el-form-item>
					<el-form-item label>
						<el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="-" :start-placeholder="$t('commonInfo.beginTime')"
							:end-placeholder="$t('commonInfo.endTime')" style="margin: 0 20px 0 10px"
							@change="handleChange"></el-date-picker>
					</el-form-item>
					<el-form-item label>
						<el-select v-model="query.paymentMode"
							:placeholder="$t('chinaWhB2COrderPage.listTabSearchItemPaymentMode_ph')"
							style="margin-right:10px;width:200px" :clearable="true">
							<el-option v-for="item in selectList" :key="item.id" :label="item.label" :value="item.value"
								 @clear="empty" @change="changePayment"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label>
						<el-select v-model="query.status" :placeholder="$t('commonInfo.statusColumn')"
							style="margin-right:10px;width:200px;margin-right:0" :clearable="true">
							<el-option v-for="item in selectListStatus" :key="item.id" :label="item.label"
								:value="item.value"  @change="changeStatus">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label>
						<el-button type="primary" style="margin: 0px 20px;margin-left:0" icon="el-icon-search" @click="handleSearch">
							{{ $t('commonInfo.searchBtn') }}</el-button>
						<el-button type="primary" style="margin: 0px 20px;margin-left:0" icon="el-icon-refresh" @click="getlist">
							{{ $t('commonInfo.refreshBtn') }}</el-button>
						<el-button style="margin: 0px 20px;margin-left:0" icon="el-icon-refresh" @click="handleReset">
							{{ $t('commonInfo.dialogBtnReset') }}</el-button>
					</el-form-item>
					<el-form-item label>
						<el-button type="primary" style="margin: 0px 20px;margin-left:0" @click="batchDownLoad"
						  :loading="downloadLoading"
							icon="el-icon-download"
							:disabled="isButton && !downloadLoading">{{downloadLoading?$t('commonInfo.actionDownloading'):$t('chinaWhB2COrderPage.listTabOperationMultipleDownloadAwb') }}
						</el-button>
					</el-form-item>
					<el-form-item label>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.exportDatabySearch')" placement="top-start">
							<el-button type="primary" style="margin: 0px 20px;margin-left:0" @click="exportExcel"
							   icon="el-icon-download"
								:loading="exportLoading"
								:disabled="exportLoading">{{ $t('commonInfo.exportExcel') }}</el-button>
						</el-tooltip>
					</el-form-item>
					
				</el-form>
				<!-- 列表展示区域 -->
				<el-row>
					<el-col :span="24">
						<div class="main">
							<div style="background-color: #fff">
								<el-table border :data="tableData" v-loading="loading" empty-text style="width:100%"
								  :row-key="getRowKey"
									ref="tableList" @selection-change="changeSelection"
									@mousedown.native="mouseDownHandler" @mouseup.native="mouseUpHandler"
									@mousemove.native="mouseMoveHandler">
									<el-table-column type="selection" :reserve-selection="true" align="center" />
									<!-- DLVD航空提单号 -->
									<el-table-column :label="$t('chinaWhB2COrderPage.listTabTableColumnAwb')"
										align="center" prop="awbNo" />
									<!-- 客户订单号 -->
									<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" align="center"
										prop="clientOrderNo" />
									<!-- Imile AWB 只有该账号可以查看 -->
									<el-table-column v-if="showTplAwbNo"
										:label="$t('chinaWhB2COrderPage.listTabTableColumnImileAwb')" align="center"
										prop="tplAwbNo" />
									<!-- 送货地址 -->
									<el-table-column :label="$t('chinaWhB2COrderPage.listTabTableColumnDestCity')"
										align="center" prop="destCity" ></el-table-column>
									<!-- 支付方式 -->
									<el-table-column :label="$t('chinaWhB2COrderPage.listTabTableColumnPaymentMode')"
										align="center" prop="paymentMode" />
									<!-- 订单类型 -->
									<el-table-column :label="$t('commonInfo.orderType')" align="center"
										prop="orderType"></el-table-column>
									
									<!-- 状态 -->
									<el-table-column :label="$t('commonInfo.statusColumn')" align="center" >
										<template slot-scope="scope" >
											<div>
												<span style="display: block;">{{ returnStatusName(scope.row) }}</span>
											</div>
										</template>
									</el-table-column>
									<!-- 创建时间 -->
									<el-table-column :label="$t('commonInfo.createTimeColumn')" align="center"
										prop="orderInitTime" width="180px">
										<template slot-scope="{ row }">
											<div>{{ dateFormats(row.orderInitTime) }}</div>
										</template>
									</el-table-column>
									<!-- 操作 -->
									<el-table-column :label="$t('commonInfo.operationColumn')" align="center"
										width="140px">
										<template slot-scope="{row}">
											<el-button type="text" size="small" @click="orderDetail(row)"
											  :loading="openLoading"
												icon="el-icon-s-order">
												{{ $t('commonInfo.orderDetails') }}</el-button>
											<el-button type="text" size="small" @click="trackInfo(row)"
											  :loading="openLoadingTwo"
												icon="el-icon-s-promotion">{{ $t('commonInfo.actiontrack') }}
											</el-button>
											<el-button type="text" size="small" @click="downLoadAWB(row)"
												:disabled="row.isAwb" icon="el-icon-download">
												{{ $t('chinaWhB2COrderPage.downloadNoodles') }}</el-button>
											<!-- <el-button type="text" size="small" @click="ShowMessage(row)"
												icon="el-icon-s-comment">
												{{ $t('chinaWhB2COrderPage.messageInformation') }}</el-button> -->
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</el-col>
				</el-row>
				<!-- 轨迹对话窗 -->
				<el-dialog :lang="$i18n.locale" :title="$t('chinaWhB2COrderPage.dialogCheckTrackTitle')" :visible.sync="popups"
					width="1100px" :close-on-click-modal="false" append-to-body class="timeline"
					style="margin-top:2vh;">
					<div class="dialog_orderNo">
						<label>{{ esnadAwbNo }}</label>
					</div>
					<el-timeline :reverse="true" v-if="isShow">
						<el-timeline-item v-for="(activity, index) in activities" :key="index" color="green"
							:timestamp="dateFormat(activity.initTime)" placement="top">
							<el-card>
								<!-- 轨迹区域 -->
								<h4>{{ activity.description }}</h4>
								<!-- location -->
								<p>
									{{ $t('commonInfo.trackLocation') + ':' }}
									<span style="font-weight:700;">{{ activity.location }}</span>
								</p>
								<!-- 备注 -->
								<p>
									{{ $t('commonInfo.remark') + ":" }}
									<span style="font-weight:700;">{{ activity.remarks }}</span>
								</p>
							</el-card>
						</el-timeline-item>
					</el-timeline>
					<!-- 自定义暂无数据 -->
					<div style="text-align:center;font-size:18px" v-else>
						{{ $t('chinaWhB2COrderPage.dialogCheckTrackNoData') }}</div>

					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="cancel">{{ $t('commonInfo.dialogBtnConfirm') }}</el-button>
					</div>
				</el-dialog>

				<!-- 订单详情对话窗 -->

				<!-- title="Order Details" -->
				<el-dialog :lang="$i18n.locale" :visible.sync="detailShow" append-to-body class="timeline" :close-on-click-modal="false"
					style="margin-top:2vh;" @close="closeDialog">
					<!-- 表单 -->
					<el-form :model="datailLists" label-width="200px" class="demo-ruleForm" :inline="true">
						<!-- 订单信息 -->
						<div style="font-size:24px;margin-bottom:10px">
							{{ $t('chinaWhB2COrderPage.dialogDetailOrderInfo') }}</div>
						<el-form-item :label="$t('chinaWhB2COrderPage.listTabTableColumnAwb')" prop="awbNo">
							<el-input v-model="datailLists.awbNo" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.createTimeColumn')" prop="orderInitTime">
							<el-input v-model="datailLists.orderInitTime" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.statusColumn')" prop="status">
							<el-input v-model="datailLists.status" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('chinaWhB2COrderPage.listTabTableColumnPaymentMode')"
							prop="paymentMode">
							<el-input v-model="datailLists.paymentMode" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.orderType')" prop="orderType">
							<el-input v-model="datailLists.orderType" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.length')" prop="length">
							<el-input v-model="datailLists.length" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.breadth')" prop="breadth">
							<el-input v-model="datailLists.breadth" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.height')" prop="height">
							<el-input v-model="datailLists.height" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.packageWeight')" prop="packageWeight">
							<el-input v-model="datailLists.packageWeight" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.clientOrderNoColumn')" prop="clientOrderNo">
							<el-input v-model="datailLists.clientOrderNo" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('chinaWhB2COrderPage.dialogDetailAmountToCollect')"
							prop="amountToCollect">
							<el-input v-model="datailLists.amountToCollect" :disabled="true"></el-input>
						</el-form-item>
						<!-- 产品描述 -->
						<el-form-item :label="$t('chinaWhB2COrderPage.dialogDetailProductDes')"
							prop="productDescription">
							<el-input v-model="datailLists.productDescription" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.memo')" prop="memo">
							<el-input v-model="datailLists.memo" :disabled="true"></el-input>
						</el-form-item>
						<!-- 发货人信息 -->
						<div style="font-size:24px;margin-bottom:10px">
							{{ $t('chinaWhB2COrderPage.dialogDetailConsignorInfo') }}</div>
						<el-form-item :label="$t('commonInfo.consignor')" prop="consignor">
							<el-input v-model="datailLists.consignor" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.contact')" prop="pickupContact">
							<el-input v-model="datailLists.pickupContact" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.address')" prop="pickupAddress">
							<el-input v-model="datailLists.pickupAddress" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.city')" prop="originCity">
							<el-input v-model="datailLists.originCity" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.country')" prop="originCountry">
							<el-input v-model="datailLists.originCountry" :disabled="true"></el-input>
						</el-form-item>
						<!-- 收货人信息 -->
						<div style="font-size:24px;margin-bottom:10px">
							{{ $t('chinaWhB2COrderPage.dialogDetailConsigneeInfo') }}</div>
						<el-form-item :label="$t('commonInfo.consignee')" prop="consignee">
							<el-input v-model="datailLists.consignee" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.contact')" prop="deliveryContact">
							<el-input v-model="datailLists.deliveryContact" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.address')" prop="deliveryAddress">
							<el-input v-model="datailLists.deliveryAddress" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.city')" prop="esnadDestCity">
							<el-input v-model="datailLists.esnadDestCity" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.country')" prop="destCountry">
							<el-input v-model="datailLists.destCountry" :disabled="true"></el-input>
						</el-form-item>
					</el-form>
					<!-- 详情表格区域 -->
					<div style="font-size:24px;margin-bottom:10px">
						{{ $t('chinaWhB2COrderPage.dialogDetailProductDetail') }}</div>
					<el-table :data="datailList" border style="width: 100%">
						<el-table-column prop="productName"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productName')" align="center">
						</el-table-column>
						<el-table-column prop="productLink"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productLink')" align="center">
						</el-table-column>
						<el-table-column prop="productHscode"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productHscode')" align="center">
						</el-table-column>
						<el-table-column prop="productSku"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productSku')" align="center">
						</el-table-column>
						<el-table-column prop="productPcs"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productPcs')" align="center">
						</el-table-column>
						<el-table-column prop="productPrice"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productPrice')" align="center">
						</el-table-column>
						<el-table-column prop="productTaxrate"
							:label="$t('chinaWhB2COrderPage.dialogDetailProduct_productTaxrate')" align="center">
						</el-table-column>
					</el-table>
					<span slot="footer" class="dialog-footer">
						<el-button type="primary" @click="detailShow = false">{{ $t('commonInfo.dialogBtnConfirm') }}
						</el-button>
					</span>
				</el-dialog>

				<!-- 留言板对话窗 -->
				<el-dialog :title="$t('chinaWhB2COrderPage.dialogMessageTitle')" :visible.sync="msgPopup"
					:close-on-click-modal="false" width="980px" append-to-body class="timeline" style="margin-top:2vh;"
					@close="closeDialog">
					<div class="timelineBox">
						<el-timeline v-loading="loading" :reverse="true" v-if="msgList.length > 0">
							<el-timeline-item v-for="(list, index) in msgList" :key="index" color="green"
								:timestamp="dateFormat(list.createTime)" placement="top">
								<el-card>
									<p>
										{{ list.createBy }}:
										<span style="font-weight:700;">{{ list.content }}</span>
									</p>
								</el-card>
							</el-timeline-item>
						</el-timeline>
						<!-- 自定义暂无数据 -->
						<div style="text-align:center;font-size:18px" v-else>{{ $t('tipsInfo.noData') }}</div>
					</div>
					<div style="margin-top: 20px;">
						<el-input type="textarea" :autosize="{ minRows: 3 }"
							:placeholder="$t('chinaWhB2COrderPage.dialogMessageInputMessage')" v-model="inputMsg">
						</el-input>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="msgSubmit">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
						</el-button>
					</div>
				</el-dialog>

				<!-- 分页组件 -->
				<div class="pageBottom">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="query.page" :page-sizes="[10, 50, 100]" :page-size.sync="query.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane :label="$t('chinaWhB2COrderPage.importTabName')" name="second">
				<div class="listHeader">
					<i></i><span>{{$t('chinaWhB2COrderPage.B2CimportTabName')}}</span>
				</div>
				<div class="allOrder">
					<el-form class="search" ref="queryForm" :inline="true">
						<el-form-item label prop="">
							<el-date-picker v-model="importSearchTime" type="daterange"
								value-format="yyyy-MM-dd HH:mm:ss" range-separator="-"
								:start-placeholder="$t('commonInfo.beginTime')"
								:end-placeholder="$t('commonInfo.endTime')" style="margin: 0 10px" clearable
								@change="handleSearchTime"></el-date-picker>
						</el-form-item>
						<el-form-item label prop="">
							<el-button type="primary" style="margin-right:15px" icon="el-icon-search" @click="handleImportQuery">
								{{ $t('commonInfo.searchBtn') }}</el-button>
							<el-button type="primary" style="margin-right:15px" icon="el-icon-refresh" @click="handleImportQuery">
								{{ $t('commonInfo.refreshBtn') }}</el-button>
						</el-form-item>
						<br />
						<el-form-item>
							<el-upload class="upload-demo"
								:action="$baseUrl + '/impParcel/impData?orderType=FDOM'"
								:before-upload="beforeUpload" :on-success="handlesuccess" :on-error="handleError"
								:on-progress="handleProgress" multiple :show-file-list="false" :file-list="fileList"
								accept=".xls, .xlsx" :headers="headers" name="parcalOrderFile"
								style="margin-right:15px">
								<el-button type="success" icon="el-icon-upload2">{{ $t('chinaWhB2COrderPage.importTabImportFDOMBtn') }}
								</el-button>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button @click="handleDown">{{ $t('chinaWhB2COrderPage.importTabDownloadTemplateBtn') }}
							</el-button>
						</el-form-item>
					</el-form>

					<!-- 列表区域 -->
					<div class="main">
						<div style="background-color: #fff">
							<el-table border :data="importData" v-loading="loading" empty-text style="width:100%;font-size: 16px;">
								<el-table-column :label="$t('chinaWhB2COrderPage.importTabTableColumnOrderCount')"
									align="center" prop="orderRows"></el-table-column>
								<el-table-column :label="$t('chinaWhB2COrderPage.importTabTableColumnOperator')"
									align="center" prop="operator"></el-table-column>
								<!-- <el-table-column type="selection" align="center"></el-table-column> -->
								<el-table-column :label="$t('chinaWhB2COrderPage.importTabTableColumnImportTime')"
									align="center" prop="impTime" width="320">
									<template slot-scope="{row}">
										<div>{{ dateFormat(row.impTime) }}</div>
									</template>
								</el-table-column>
								<!-- 操作 -->
								<el-table-column :label="$t('chinaWhB2COrderPage.importTabTableColumnAction')"
									align="center" width="140px">
									<template slot-scope="{row}">
										<!-- <el-button type="primary" size="small" :loading="true" v-if="isShow">Generating AWB…</el-button> -->
										<el-button type="text" size="small" @click="downLoad(row.pdfsLink)"
										  icon="el-icon-download"
											v-if="row.pdfsLink">{{ $t('commonInfo.actionDownload') }}</el-button>
										<el-button type="text" size="small" :disabled="true" v-else icon="el-icon-loading">
											{{ $t('tipsInfo.generating') + '...' }}</el-button>
										<el-button type="text" size="small" style="margin-left: 0px;" @click="exportLoad(row.id)" icon="el-icon-download">
											{{ $t('commonInfo.actionDownloadSkuList') }}
										</el-button>
										<el-button type="text" size="small" style="margin-left: 0px;" @click="exportOrderDetail(row.id)" icon="el-icon-download">
											{{ $t('chinaWhB2COrderPage.importTabTableColumnActionExportOrder') }}
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>

					<!-- 导入Excel后错误的对话框 -->
					<el-dialog title="Excel Data" :visible.sync="dialogImportTableVisible"
						:close-on-click-modal="false">
						<el-table :data="gridData">
							<el-table-column property="clientOrderNo" :label="$t('commonInfo.clientOrderNoColumn')"
								align="center"></el-table-column>
							<el-table-column property="errorMsg" :label="$t('commonInfo.result')" align="center">
							</el-table-column>
						</el-table>
					</el-dialog>

					<div class="pageBottom">
						<el-pagination @size-change="handleImportSizeChange" @current-change="handleImportCurrentChange"
							:current-page="importQuery.page" :page-sizes="[10, 20, 30, 50]"
							:page-size="importQuery.limit" layout="total, sizes, prev, pager, next, jumper"
							:total="importTotal"></el-pagination>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import {downloadStream} from "@/utils/downloadFileStream";
export default {
	name: "order",
	mounted() {
		window.onresize = () => {
			this.tableHeight = document.body.clientHeight - 260;
		};
	},
	data() {
		return {
			activeName: 'first',
			//原B2C列表页面信息，参数直接复制
			value1: [], // 时间
			tableHeight: window.innerHeight - 260,
			query: {
				limit: 10,
				page: 1,
				createDateBegin: "", //开始时间
				createDateEnd: "",// 结束时间
				orderType: 'FDOM',//订单类型
				clientOrderNos: [], // 订单号搜索的值
				esnadAwbNos: [], // 航空提单号
				status: "",//订单类型
			},
			total: 0, // 数据的总条数
			input: "", // 订单号搜索的值
			boxNum: "", // 航空提单号
			status: "",
			tableData: [],
			loading: false, // loading
			openLoading:false,
			openLoadingTwo:false,
			submitLoading:false,
			uploadloading: false,
			downloadLoading:false,
			exportLoading:false,
			selectList: [
				{ id: 1, value: "PPD", label: "PPD" },
				{ id: 2, value: "COD", label: "COD" }
			],
			paymentMode: "",
			// 轨迹列表对话框
			activities: [],
			popups: false,
			title: "DLVD Tracking",
			isShow: true,
			isNone: true,
			//监听多选框选中状态
			selectionStatus: [],
			ids:[],
			// 状态下拉查询
			/* selectListStatus: [
				{ value: "1", label: "Pending" },
				{ value: "2", label: "Returned" },
				{ value: "3", label: "Delivered" }
			], */
			selectStatus: "",
			// 批量下载
			parcelOrderIds: [],
			// 订单详情对话窗
			detailShow: false,
			datailList: [],
			datailLists: {},
			isButton: true,
			isButtons: true,

			mouseoffset: 0,//鼠标滑动的距离
			mouseFlag: false,//判断是否在表格滑动

			//留言板信息
			msgPopup: false,
			msgList: [],
			inputMsg: '',
			msgOrderNo: '',
			orderTime: '',//后台处理不了时间，需要重新上传时间

			//合并的B2C Import.vue 页面，参数修改
			importData: [],//表格数据
			importTotal: 0, // 数据的总条数
			importSearchTime: [], // 搜索时间
			importQuery: {
				limit: 10,
				page: 1,
				impDateBegin: "", //开始时间
				impDateEnd: "" // 结束时间
			},
			// 按钮的隐藏显示
			isShowImportPdf: true,
			dialogImportTableVisible: false,
			gridData: [],
			fileList: [],
			// 手动给接口添加token值
			headers: {},
			isQiJian: false,//判断是否为七剑账号
			showTplAwbNo: false,// 显示Imile单号信息
			esnadAwbNo: '',

		};
	},
	computed: {
		// 状态下拉查询
		selectListStatus() {
			return [
				{ value: "Returned", label: this.$t('B2CStatus.returned') },
				{ value: "Delivered", label: this.$t('B2CStatus.delivered') },
				{ value: "Pending", label: this.$t('B2CStatus.pending') }
			]
		}
	},
	methods: {
		// 根据状态码显示信息 中英文
		returnStatusName(row) {
			let name = '';
			if (row.status == "777") {
				name = this.$t('B2CStatus.returned');
			} else if (row.status == "999") {
				name = this.$t('B2CStatus.delivered');
			} else {
				name = this.$t('B2CStatus.pending');
			}
			return name
		},
		//实现表格列数过多可鼠标拖动功能
		mouseDownHandler(e) {
			this.mouseFlag = true;
			this.mouseoffset = e.clientX;
		},
		mouseUpHandler(e) {
			this.mouseFlag = false;
		},
		mouseMoveHandler(e) {
			let tableBody = this.$refs.tableList.bodyWrapper;
			if (this.mouseFlag) {
				tableBody.scrollLeft -= (-this.mouseoffset + (this.mouseoffset = e.clientX));
			}
		},
		handleClick(tab, event) {
			if (tab.name == "first") {
				this.getlist();
			} else if (tab.name == "second") {
				this.getImportlist();
			}
		},
		//获取当前用户信息
		getClientInfo() {
			let params = window.sessionStorage.getItem("params");
			//将JSON字符串转换成对象
			let oParams = JSON.parse(params);
			// this.isQiJian = oParams.username == 'xushuyuan@lingeriedealy.com'?true:false;
			if (oParams.username == 'xushuyuan@lingeriedealy.com' || oParams.username == 'xiaohu') {
				this.isQiJian = true;
			}

			let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
			console.log(customInfo);
			if (customInfo.clientId == 1181) {
				this.showTplAwbNo = true;
			} else {
				this.showTplAwbNo = false;
			}
		},

		// B2C列表 方法
		// 监听支付类型的选项
		changePayment(val) {
			console.log(val);
		},
		changeStatus(val) {
			console.log(val);
		},
		// 查询
		handleQuery() {
			this.query.page = 1;
			this.getlist();
		},
		// 监听日期选择器
		handleChange(val) {
			// console.log(val);
			if (!val) {
				// this.value1 = [];
				this.query.createDateBegin = "";
				this.query.createDateEnd = "";
			}
		},
		// 获取列表
		async getlist() {
			this.loading = true;

			if (this.paymentMode.length != 0) {
				if (this.paymentMode == 1) {
					this.paymentMode = "PPD";
				} else {
					this.paymentMode = "COD";
				}
			}
			const res = await this.$http.post("/tocorder/all", this.query);
			if (res.code == 200) {
				// 分页数据条数
				this.total = res.data.totalCount;
				// console.log(res);
				this.loading = false;
				this.tableData = res.data.list;
				this.ids = [];
				this.selectionStatus = [];
				this.$refs.tableList.clearSelection();

				this.tableData.forEach(item => {
					item.isAwb = false;
					let numbers = parseInt(item.status);
					if (numbers >= 200) {
						item.isAwb = true;
					} else {
						item.isAwb = false;
					}

					if (item.status == "777") {
						item.status = this.$t('B2CStatus.returned');
					} else if (item.status == "999") {
						item.status = this.$t('B2CStatus.delivered');
					} else {
						item.status = this.$t('B2CStatus.pending');
					}
					// console.log(item.status + "---");
				});
			} else {
				this.$message.error(res.msg);
			}
		},
		//搜索
		handleSearch() {
			this.query.page = 1;
			if(this.input !=''){
				this.query.clientOrderNos = this.strToArr(this.input);
			}else{
				this.query.clientOrderNos = [];
			}

			if(this.boxNum != ''){
				this.query.esnadAwbNos = this.strToArr(this.boxNum);
			}else{
				this.query.esnadAwbNos = [];
			}

			this.getlist();
		},
		// 重置
		handleReset(){
			this.input = '';
			this.boxNum = '';
			this.query = {
				limit: 10,
				page: 1,
				createDateBegin: "", //开始时间
				createDateEnd: "",// 结束时间
				orderType: 'FDOM',//订单类型
				clientOrderNos: [], // 订单号搜索的值
				esnadAwbNos: [], // 航空提单号
				status: "",//订单类型
			}
			this.getlist();
		},
		// 导出表格
		exportExcel() {
			if(this.input !=''){
				this.query.clientOrderNos = this.strToArr(this.input);
			}else{
				this.query.clientOrderNos = [];
			}
			
			if(this.boxNum != ''){
				this.query.esnadAwbNos = this.strToArr(this.boxNum);
			}else{
				this.query.esnadAwbNos = [];
			}
			this.exportLoading = true;
			const res = this.$http
				.post("/tocorder/exportBySearch", this.query,{
			    responseType:'blob',
			  })
				.then(res => {
					if (res.code != 500) {
						// console.log(res);
						downloadStream(res,'xlsx', this.$t('menuItem.ksaWhItem.name') + 'B2C Order');
						this.exExcel = [];
						this.$message({
							type: "success",
							message: this.$t('tipsInfo.exportSuccess')
						});
					}
					this.exportLoading = false;
				}).catch(err => {
					this.exportLoading = false;
					this.$message.error(err.msg);
				});
		},
		// 查询
		querying() {
			this.query.page = 1;
			let Num = this.input;
			this.getlist();
		},
		// 清空事件
		empty() {
			this.getlist();
		},
		// 分页条数
		handleSizeChange(size) {
			this.query.limit = size;
			this.query.page = 1;
			this.getlist();
		},
		// 分页页数
		handleCurrentChange(page) {
			this.query.page = page;
			console.log(this.query);
			this.getlist();
		},
		// 分页条数
		handleImportSizeChange(size) {
			this.importQuery.limit = size;
			this.importQuery.page = 1;
			this.getImportlist();
		},
		// 分页页数
		handleImportCurrentChange(page) {
			this.importQuery.page = page;
			this.getImportlist();
		},

		// 查询订单轨迹
		async trackInfo(row) {
			console.log(row);
			this.esnadAwbNo = row.awbNo;
			this.openLoadingTwo = true;
			const res = await this.$http.get("/tocorder/dlvd/" + this.esnadAwbNo);
			if (res.code == 200) {
				this.activities = res.data;
				this.popups = true;
				this.openLoadingTwo = false;
				if (res.data.length == "0") {
					this.isShow = false;
				} else {
					this.isShow = true;
				}
			}else{
				this.openLoadingTwo = false;
				this.$message.error(res.msg);
			}
		},
		//AWB下载
		downLoadAWB(row) {
			console.log(row);
			window.open(row.awbLinkPdf);
			let pdfLink = row.awbLinkPdf;
			pdfLink.print();
		},
		// 获取唯一标识
		getRowKey(row){
		  return row.id;
		},
		//监听多选框状态
		changeSelection(e) {
			this.ids = e.map(item => item.id);
			this.selectionStatus = e;
			console.log(this.selectionStatus);
			if (this.selectionStatus.length >= 2) {
				this.isButton = false;
			} else {
				this.isButton = true;
			}
			if (this.selectionStatus.length >= 1) {
				this.isButtons = false;
			} else {
				this.isButtons = true;
			}
		},

		// 订单详情
		async orderDetail(row) {
			console.log(row);
			let id = row.id;
			this.openLoading = true;
			const res = await this.$http.get("/tocorder/info/detail/" + id);
			if (res.code == 200) {
				this.detailShow = true;
				// this.datailList.push(res.data);
				console.log(this.datailList);

				if (res.data.status == "777") {
					res.data.status = this.$t('B2CStatus.returned');
				} else if (res.data.status == "999") {
					res.data.status = this.$t('B2CStatus.delivered');
				} else {
					res.data.status = this.$t('B2CStatus.pending');
				}
				this.datailLists = res.data;
				this.datailList = res.data.productInfoList;
				this.openLoading = false;
			} else {
				this.openLoading = false;
				this.$message.error(res.msg);
			}
		},
		// 清空弹窗数据
		closeDialog() {
			this.datailList = [];

			this.msgPopup = false;
			this.msgList = [];
			this.inputMsg = '';
		},

		// 批量下载AWB
		async batchDownLoad() {
			this.selectionStatus.forEach(item => {
				console.log(item.id);
				this.parcelOrderIds.push(item.id);
			});
			if (this.parcelOrderIds.length != 0) {
				this.downloadLoading = true;
				const res = await this.$http.post(
					"/tocorder/mergeParcelOrderAwbByIds",
					{
						parcelOrderIds: this.parcelOrderIds
					}
				);

				if (res.code == 200) {
					this.downloadLoading = false;
					window.open(res.msg);
					this.parcelOrderIds = [];
					this.ids = [];
					this.selectionStatus = [];
					this.$refs.tableList.clearSelection();
				} else {
					this.downloadLoading = false;
					this.$message.error(res.msg);
				}
			} else {
				this.$message.error("Please select order");
			}
		},
		blurFun() {
			this.boxNum = "";
			this.input = "";
		},
		cancel() {
			this.popups = false;
		},
		//打开留言板
		ShowMessage(row) {
			this.msgPopup = true;
			this.msgOrderNo = row.awbNo;
			this.orderTime = row.orderInitTime;
			this.getMsgList();
		},
		// 获取留言板列表信息
		async getMsgList() {
			this.loading = true;
			const res = await this.$http.get("/tocorder/message/info/" + this.msgOrderNo);
			if (res.code == 200) {
				this.msgList = res.data;
				this.loading = false;
			} else {
				this.$message.error(res.msg);
				this.loading = false;
			}
		},
		//发布填写的留言信息
		async msgSubmit() {
			if(this.inputMsg == ''){
				return this.$message.warning(this.$t('tipsInfo.inputTips'))
			}
			let obj = {
				orderId: this.msgOrderNo,
				content: this.inputMsg,
				orderTime: this.orderTime
			}
			this.submitLoading = true;
			const res = await this.$http.post("/tocorder/message/create", obj);
			if (res.code == 200) {
				this.$message.success(this.$t('chinaWhB2COrderPage.dialogMessageSubmitSuccess'));
				this.inputMsg = '';
				this.submitLoading = false;
				this.getMsgList();
			} else {
				this.submitLoading = false;
				this.$message.error(res.msg);
			}
		},

		// B2C import页面方法
		// 查询
		handleImportQuery() {
			this.query.page = 1;
			this.getImportlist();
		},

		handleSearchTime(val) {
			if (!val) {
				// this.value1 = [];
				this.importQuery.impDateBegin = "";
				this.importQuery.impDateEnd = "";
			} else {
				this.importQuery.impDateBegin = val[0];
				this.importQuery.impDateEnd = val[1];
			}
		},
		// 获取列表
		async getImportlist() {
			this.loading = true;
			const res = await this.$http.get("/impParcel/list", {
				params: this.importQuery
			});
			if (res.code == 200) {
				this.importData = res.data.list;
				this.importTotal = res.data.totalCount;
				// console.log(res);
				if (this.importData.pdfsLink != "") {
					this.isShowImportPdf = false;
				} else {
					this.isShowImportPdf = true;
				}
				if (!this.value1) {
					this.query.impDateBegin = "";
					this.query.impDateEnd = "";
				}
			}
			this.loading = false;
		},
		// 下载
		downLoad(val) {
			window.open(val);
		},
		// 导出订单清单
		async exportLoad(val) {
			let ids = val;
			const res = this.$http
				.post("/impParcel/exportOrderInfo/" + ids,null,{
			    responseType:'blob',
			  })
				.then(res => {
					if (res.code != 500) {
						downloadStream(res,'xlsx','B2C Order SKU Info');
						this.$message({
							type: "success",
							message: this.$t('tipsInfo.exportSuccess')
						});
					}
				}).catch(err => {
					this.$message.error(err.msg);
				});
		},
		// 导出订单详情
		async exportOrderDetail(id){
			const res = this.$http
				.post("/impParcel/exportAllOrderInfo/" + id,null,{
			    responseType:'blob',
			  })
				.then(res => {
					if (res.code != 500) {
						downloadStream(res,'xlsx','B2C Order Detail Info');
						this.$message({
							type: "success",
							message: this.$t('tipsInfo.downloadSuccess')
						});
					}
				}).catch(err => {
					this.$message.error(err.msg);
				});
		},
		//上传成功
		handlesuccess(files, fileList) {
			this.uploadloading = false;
			if (files.code == 200) {
				if (files.data.success) {
					this.$message.success(this.$t('tipsInfo.uploadSuccess'));
					this.getImportlist();
					return;
				} else if (files.data.success == false) {
					this.gridData = files.data.parcelOrderErrorInfoList;
					this.dialogImportTableVisible = true;
				}
			} else if (files.code == 500) {
				this.$message.error(files.msg);
			}
			// console.log(files);

			if (files.code == 401) {
				this.$message.error(this.$t('tipsInfo.loginOutTips'));
				window.sessionStorage.removeItem("token");
				return this.$router.push({
					path: "/login"
				});
			}
			// console.log(files);
		},
		async handleDown() {
			//获取沙特仓库类型字典
			const res = await this.$http.get("/toborder/system/dict/data/list?dictType=sys_template_url");
			if (res.code == 200) {
				if (res.rows.length != 0) {
					res.rows.forEach(item => {
						if (item.dictLabel == 'FDOM_Template') {
							window.open(item.remark);
						}
					})
				}
			}

		},
		// 上传时触发
		beforeUpload(file) {
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
			var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
			const extension = testmsg === "xls";
			const extension2 = testmsg === "xlsx";
			if (!extension && !extension2) {
				this.$message.warning(
					this.$t('tipsInfo.uploadExcelTypeTips')
				);
			}
			return extension || extension2;
		},
		// 上传失败
		handleError(err, file, fileList) {
			console.log(err);
			console.log(file);
			console.log(fileList);
			this.uploadloading = false;
		},
		// 上传时
		handleProgress(event, file, fileList) {
			this.uploadloading = true;
			// this.$message.warning("Uploading, please hold on");
		},

		// 格式化日期
		dateFormat: function (time) {
			var date = new Date(time);
			var year = date.getFullYear();
			/* 在日期格式中，月份是从0开始的，因此要加0
			 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
			 * */
			var month =
				date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1;
			var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
			var hours =
				date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
			var minutes =
				date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
			var seconds =
				date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
			// 拼接
			return (
				year +
				"-" +
				month +
				"-" +
				day +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds +
				" " +
				"Time Zone：Beijing"
			);
		},
		dateFormats: function (time) {
			var date = new Date(time);
			var year = date.getFullYear();
			/* 在日期格式中，月份是从0开始的，因此要加0
			 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
			 * */
			var month =
				date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1;
			var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
			var hours =
				date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
			var minutes =
				date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
			var seconds =
				date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
			// 拼接
			return (
				year +
				"-" +
				month +
				"-" +
				day +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds
			);
		}
	},
	created() {
		let token = window.sessionStorage.getItem("token");
		this.headers = {
			Authorization: token,
		}

		this.getClientInfo();
		this.getlist();
	},

	watch: {
		value1() {
			if (this.value1.length === 2) {
				console.log(this.value1);
				this.query.createDateBegin = this.value1[0];
				this.query.createDateEnd = this.value1[1];
			}
		}
	}
};
</script>

<style scoped>
.listHeader {
	width: 100%;
	height: 50px;
	line-height: 50px;
	font-size: 22px;
}

.listHeader i {
	width: 4px;
	height: 40px;
	display: inline-block;
	background-color: #0077AA;
	float: left;
	margin: 5px 10px;
}

.listHeader span {
	display: inline-block;
	float: left;
}
	.el-button+.el-button {
		margin-left: 0px;
	}
.app-container {
	padding: 10px;
}

.dialog_orderNo {
	width: 100%;
	height: 50px;
	line-height: 40px;
}

.dialog_orderNo label {
	margin-left: 40px;
	font-weight: bold;
	border-radius: 8px;
	font-size: 20px;
	padding: 5px 15px;
	border: 1px solid #EBEEF5;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
}

.search {
	/* height: 70px; */
	padding: 10px 20px 0;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;
}

.el-button--small,
.el-button--small.is-round {
	height: 40px;
}

.main {
	padding: 20px;
	background-color: rgb(239, 239, 239);
}

.pageBottom {
	padding: 20px 0 0 50px;
}

.open {
	text-align: center;
	font-size: 16px;
	color: #909399;
}

.el-table td,
.el-table th.is-leaf {
	text-align: center !important;
}

.el-button--small,
.el-button--small.is-round {
	padding: 12px 18px;
}

/deep/.el-dialog {
	margin-top: 2vh !important;
}

.search /deep/.el-textarea__inner {
	width: 250px !important;
	line-height: 28px !important;
}

/deep/.el-dialog {
	z-index: 1001 !important;
	width: 1000px !important;
}

/deep/.el-dialog__body {
	padding: 0 20px;
}

/deep/.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner {
	width: 300px;
}

.timelineBox {
	width: 100%;
	min-height: 350px;
}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		::v-deep .el-tabs__nav{
			float: right;
		}
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
	}
</style>